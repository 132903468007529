export const formatMoney = (value) => {
  return value
    ? new Intl.NumberFormat("en-IN", {
        style: "decimal",
        maximumFractionDigits: 2,
      }).format(value)
    : value;
};

export const getDivisionName = (divisionNo) => {
  let divisionName = "";
  if (divisionNo) {
    if (divisionNo === "10") {
      return "Dixcy"; // Core
    } else if (divisionNo === "20") {
      return "Maximus"; // Max
    } else if (divisionNo === "30") {
      return "Levis";
    } else if (divisionNo === "40") {
      return "Enamor";
    } else if (divisionNo === "50") {
      return "Thermal";
    }
  }
  return divisionName;
};

export const getMonthName = (month) => {
  let monthName = "";
  if (month) {
    if (month === "01") {
      monthName = "January";
    } else if (month === "02") {
      monthName = "February";
    } else if (month === "03") {
      monthName = "March";
    } else if (month === "04") {
      monthName = "April";
    } else if (month === "05") {
      monthName = "May";
    } else if (month === "06") {
      monthName = "June";
    } else if (month === "07") {
      monthName = "July";
    } else if (month === "08") {
      monthName = "August";
    } else if (month === "09") {
      monthName = "September";
    } else if (month === "10") {
      monthName = "October";
    } else if (month === "11") {
      monthName = "November";
    } else if (month === "12") {
      monthName = "December";
    }
  }
  return monthName;
};
