import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { Box, Button, Typography } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import {
  PersonSearch,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  exportToExcelOutstanding,
  getAllOutstandingBillNew,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import Breadcrumb from "../../common/breadcrumb";
import CommonCardDesign from "../../common/commonCardDesign";
import {
  CardHeaderValue,
  outstandingBillTableDetailedData,
  outstandingBillTableDetailedHeader,
  outstandingBillTableMainData,
  outstandingBillTableMainHeader,
} from "../../common/commonCardConstant";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useCustomerCode } from "../../context/CustomerCodeProvider";
import { formatMoney } from "../../../constants/utils";
import Marquee from "react-fast-marquee";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
  customAutocompleteInput: {
    "& .MuiInputBase-sizeSmall": {
      height: "40px" /* Adjust the height as needed */,
    },
  },
  //.css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall
}));

const status = [
  {
    value: "Y",
    label: "Active",
  },
  {
    value: "N",
    label: "In-Active",
  },
];

const OutstandingBill = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [data, setData] = useState([]);
  const [consolidateData, setConsolidateData] = useState([]);
  const [consolidateListByDivision, setConsolidateListByDivision] = useState(
    []
  );

  const [nextInvoiceList, setNextInvoiceList] = useState([]);
  const [nextBillList, setNextBillList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetailedView, setShowDetailedView] = useState(false);

  const history = useNavigate();
  const filterInitialValues = {
    searchDivision: "",
    searchInvoiceNumber: "",
    searchDate: "", //moment(new Date()).format("YYYY-MM-DD"),
  };
  const [searchData, setSearchData] = useState(filterInitialValues);
  const { selectedCustomerCode, selectedCustomerName } = useCustomerCode();

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    console.log(value, "value");
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    filterData();
  }, [selectedCustomerCode]);

  /* useEffect(() => {
    setSearchData((prevSearchData) => ({
      ...prevSearchData,
      searchCustomerCode: selectedCustomerCode,
    }));
  }, [selectedCustomerCode, setSearchData]); */

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getAllOutstandingBillNew(selectedCustomerCode, "", page, rowsPerPage).then(
      (res) => {
        setData(res.response.responseObject);
        setConsolidateData(res.response.responseMap.consolidateList);
        setNextInvoiceList(res.response.responseMap.nextInvoiceListNew);
        //setNextInvoiceList(res.response.responseMap.nextInvoiceList);
        setNextBillList(res.response.responseMap.nextBillList);
        setConsolidateListByDivision(
          res.response.responseMap.consolidateListByDivision
        );
        setLoading(false);
      }
    );
  };

  // Group by division
  const groupedByDivision = consolidateListByDivision?.reduce((acc, item) => {
    if (!acc[item.divisionName]) {
      acc[item.divisionName] = [];
    }
    acc[item.divisionName].push(item);
    return acc;
  }, {});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const filterData = () => {
    setLoading(true);
    getAllOutstandingBillNew(
      selectedCustomerCode,
      searchData.searchDate,
      page,
      rowsPerPage
    ).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setNextInvoiceList(res.response.responseMap.nextInvoiceListNew);
      setNextBillList(res.response.responseMap.nextBillList);
      setConsolidateListByDivision(
        res.response.responseMap.consolidateListByDivision
      );
      setConsolidateData(res.response.responseMap.consolidateList);
    });

    //  searchOutstandingBillData(searchData, page, rowsPerPage).then((res) => {
    //    setData(res.responseMap.data);
    // });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelOutstanding(
      selectedCustomerCode,
      `${selectedCustomerName.replace(" ", "_")}_${moment(new Date()).format(
        "DD_MM_YYYY_HH_mm_SS"
      )}`
    ).then(() => {
      setLoading(false);
    });
  };

  const redirectPath = (path, division, customerCode) => {
    history(`${process.env.PUBLIC_URL}/reports${path}`, {
      state: {
        divisionParam: division,
        customerCodeParam: customerCode,
      },
    });
  };

  const renderItems = (items) => {
    let netAmountByDivision = 0;
    return items.map((item, index) => {
      netAmountByDivision += item.paymentDue - item.cdAmount;
      return (
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
              {item.plantName.toLowerCase().includes("modenik".toLowerCase())
                ? "Payment Outstanding"
                : item.plantName}
            </Typography>
            <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
              {formatMoney(item.paymentDue.toFixed(0))}
            </Typography>
          </Box>
          {/* {item.plantName.toLowerCase().includes("modenik".toLowerCase()) && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
                CD Amount
              </Typography>
              <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
                {formatMoney(item.cdAmount?.toFixed(0))}
              </Typography>
            </Box>
          )} */}
          {items.length - 1 === index &&
            item.division != null &&
            item.division !== "" &&
            item.division !== "null" && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
                  Net Amount
                </Typography>
                <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
                  {formatMoney(netAmountByDivision.toFixed(0))}
                </Typography>
              </Box>
            )}
        </>
      );
    });
  };

  const getNextBillListRow = (name, value) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
          {name}
        </Typography>
        <Typography color={"primary"} fontSize={14} marginBottom={0.5}>
          {formatMoney(value.toFixed(0))}
        </Typography>
      </Box>
    );
  };

  return (
    <Fragment>
      <Breadcrumb title="Outstanding Bill" />
      {loading ? <CommonLoader /> : null}

      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Col lg={12} xs={12} style={{ marginBottom: 10 }}>
            {nextInvoiceList.length > 0 && (
              <Marquee pauseOnHover spacing={20} speed={50}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#8E99A4",
                    padding: 10,
                  }}
                >
                  {nextInvoiceList.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                            color: "#ffffff",
                            letterSpacing: 0.5,
                          }}
                        >
                          {`Next Payment in due `}
                          <Typography
                            component={"span"}
                            style={{
                              color: "#ffffff",
                              textDecorationLine: "underline",
                              fontSize: "15px",
                            }}
                          >
                            {`${item.divisionName} Rs. ${formatMoney(
                              item.balanceAmount.toFixed(0)
                            )}`}
                          </Typography>
                          {` on invoice ${item.invoiceNumber}. `}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Marquee>
            )}
          </Col>

          {/* <Col lg={Object.keys(groupedByDivision)?.length > 2 ? 9 : 6} xs={12}> */}
          <Col sm={12} xs={12}>
            <Grid container spacing={1.5}>
              {!isEmpty(nextBillList) &&
                nextBillList.map((item, index) => (
                  <Grid
                    item
                    sm={nextBillList.length === 1 ? 4 : 3}
                    xs={12}
                    style={{ display: "flex" }}
                  >
                    <Card
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <CardBody className={classes.cardbody}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                            letterSpacing: 0.5,
                            textAlign: "center",
                          }}
                          color={"primary"}
                          fontWeight={"bold"}
                        >
                          {`Next Payment ${item.divisionName ?? ""}`}
                        </Typography>
                        {getNextBillListRow(
                          `Next Invoices ( ${item.invoiceNumber})`,
                          item.balanceAmount
                        )}
                        {item.firstCDAmount > 0 &&
                          getNextBillListRow(
                            `Pay by ${item.firstCDDueDate} to get CD ${item.firstCDPer}%`,
                            item.firstCDAmount
                          )}
                        {item.secondCDAmount > 0 &&
                          getNextBillListRow(
                            `Pay by ${item.secondCDDueDate} to get CD ${item.secondCDPer}%`,
                            item.secondCDAmount
                          )}
                        {item.thirdCDAmount > 0 &&
                          getNextBillListRow(
                            `Pay by ${item.thirdCDDueDate} to get CD ${item.thirdCDPer}%`,
                            item.thirdCDAmount
                          )}
                        {item.fourthCDAmount > 0 &&
                          getNextBillListRow(
                            `Pay by ${item.fourthCDDueDate} to get CD ${item.fourthCDPer}%`,
                            item.fourthCDAmount
                          )}
                      </CardBody>
                    </Card>
                  </Grid>
                ))}
              {Object.keys(groupedByDivision).map(
                (division, index) =>
                  // containsModenik(groupedByDivision[division]) && (
                  division != null &&
                  division != "" &&
                  division != "null" && (
                    <Grid
                      item
                      sm={Object.keys(groupedByDivision)?.length > 2 ? 3 : 6}
                      xs={12}
                      style={{ display: "flex" }}
                    >
                      <Card
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <CardBody className={classes.cardbody}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              textTransform: "capitalize",
                              letterSpacing: 0.5,
                              textAlign: "center",
                            }}
                            color={"primary"}
                            fontWeight={"bold"}
                          >
                            {division != null &&
                            division != "" &&
                            division != "null"
                              ? division
                              : "Consolidate"}
                          </Typography>
                          {renderItems(groupedByDivision[division])}
                        </CardBody>
                      </Card>
                    </Grid>
                  )
                //)
              )}
            </Grid>
          </Col>
          {/* <Col lg={6} xs={12}>
            <Card className="height-equal">
              <CardBody className={classes.cardbody}>
                <div className="user-status table-responsive products-table text-center">
                  <table className="table table-bordernone mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Particulars</th>
                        <th scope="col">Invoice Amt</th>                        
                        <th scope="col">Balance Amt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consolidateData.map((data) => (
                        <tr>
                          <td>{data.plantName}</td>
                          <td className="digits">
                            {formatMoney(data.invoiceAmount?.toFixed(0))}
                          </td>
                          <td
                            className="digits"
                            onClick={() => {
                              if (data["redirectPath"] != null) {
                                redirectPath(
                                  data["redirectPath"],
                                  data["redirectDivision"],
                                  data["customerCode"]
                                );
                              }
                            }}
                          >
                            {formatMoney(data.balanceAmount?.toFixed(0))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col lg={Object.keys(groupedByDivision)?.length > 2 ? 3 : 6} xs={12}> */}
          <Col sm={12} xs={12}>
            <Card>
              <CardBody className={classes.cardbody}>
                <Grid container spacing={1.5} className={classes.muiGrid}>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      fullWidth
                      //select
                      label="Division"
                      size="small"
                      id="fullWidth"
                      name="searchDivision"
                      value={searchData.searchDivision}
                      onChange={onFilterInputChange}
                    >
                      {/* 
                      {data.map((option) => (
                        <MenuItem
                          key={option.customerCode}
                          value={option.customerCode}
                        >
                          {option.customerCode}
                        </MenuItem>
                      ))} */}
                    </TextField>
                  </Grid>
                  {/* <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      // select
                      label="Invoice Number"
                      size="small"
                      id="fullWidth"
                      name="searchInvoiceNumber"
                      value={searchData.searchInvoiceNumber}
                      onChange={onFilterInputChange}
                    >
                      
                    </TextField>
                  </Grid> */}

                  <Grid item sm={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Date"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="fullWidth"
                      name="searchDate"
                      value={searchData.searchDate}
                      onChange={onFilterInputChange}
                    />
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    xs={12}
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => filterData()}
                    >
                      <PersonSearch></PersonSearch>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        setSearchData(filterInitialValues);
                        loadListData();
                      }}
                    >
                      <Refresh></Refresh>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        exportData();
                      }}
                    >
                      <SimCardDownloadRounded></SimCardDownloadRounded>
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Col>

          <Box display={"flex"}>
            <Typography variant="h5">{`${selectedCustomerName} - ${selectedCustomerCode}`}</Typography>
            <Button
              style={{ marginLeft: 10, marginBottom: 4 }}
              color="primary"
              variant="contained"
              type="button"
              onClick={() => setShowDetailedView(!showDetailedView)}
            >
              {`View ${!showDetailedView ? "Detailed" : "Main"} data`}
            </Button>
          </Box>
          <Card className={classes.cardbody}>
            <div>
              <CommonCardDesign
                tableData={data}
                tableHeaders={
                  showDetailedView
                    ? outstandingBillTableDetailedHeader
                    : outstandingBillTableMainHeader
                }
                tableColumns={
                  showDetailedView
                    ? outstandingBillTableDetailedData
                    : outstandingBillTableMainData
                }
                tableModal={CardHeaderValue}
                rowsPerPageOptions={[25, 50, 100]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ float: "right" }}
                displayPagination={false}
                dueDate={false}
              />
            </div>
          </Card>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default OutstandingBill;
