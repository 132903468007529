import moment from "moment";
import httpConnection from "../api/httpconnection";

const headers = {
  "Content-Type": "application/json; charset=utf-8",
};

const headersMultipart = {
  "content-type": "multipart/form-data",
};

export async function logout() {
  const response = await httpConnection.get(`logout`, {}, { headers: headers });
  return {
    response: response.data,
  };
}

export async function loginAuth(values) {
  const data = {
    username: values.username,
    password: values.password,
  };
  const response = await httpConnection.post(`user/loginAuth`, data, {
    headers: headers,
  });
  return {
    response: response.data,
  };
}

export async function saveOrUpdateRegionMaster(data) {
  const response = await httpConnection.post(
    `region-master/saveOrUpdateRegionMaster`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllRegionMasters(pageNo, pageSize) {
  const response = await httpConnection.get(
    `region-master/getAllRegionMasters?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchRegionData(values, pageNo, pageSize) {
  const data = {
    regionName: values.searchRegionName,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `region-master/searchRegionMaster`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        regionMasterId: item.region_master_id,
        regionName: item.region_name,
        remarks: item.remarks,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function saveOrUpdateStateMaster(data) {
  const response = await httpConnection.post(
    `state-master/saveOrUpdateStateMaster`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllStateMasters(pageNo, pageSize) {
  const response = await httpConnection.get(
    `state-master/getAllStateMasters?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchStateData(values, pageNo, pageSize) {
  const data = {
    stateName: values.searchStateName,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `state-master/searchStateMaster`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        stateMasterId: item.state_master_id,
        stateName: item.state_name,
        remarks: item.remarks,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function saveOrUpdateDivisionMaster(data) {
  const response = await httpConnection.post(
    `division-master/saveOrUpdateDivisionMaster`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllDivisionMasters(pageNo, pageSize) {
  const response = await httpConnection.get(
    `division-master/getAllDivisionMasters?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchDivisionData(values, pageNo, pageSize) {
  const data = {
    divisionName: values.searchDivisionName,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `division-master/searchDivisionMaster`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        divisionMasterId: item.division_master_id,
        divisionName: item.division_name,
        remarks: item.remarks,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function saveOrUpdateGroup(data) {
  const response = await httpConnection.post(`group/saveOrUpdateGroup`, data, {
    headers: headers,
  });
  return {
    response: response.data,
  };
}

export async function getAllGroup(pageNo, pageSize) {
  const response = await httpConnection.get(
    `group/getAllGroups?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchGroupData(values, pageNo, pageSize) {
  const data = {
    groupName: values.searchGroupName,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(`group/searchGroup`, data, {
    headers: headers,
  });

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        groupId: item.group_id,
        groupName: item.group_name,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function saveOrUpdateUsers(data) {
  const response = await httpConnection.post(`user/saveOrUpdateUser`, data, {
    headers: headers,
  });
  return {
    response: response.data,
  };
}

export async function getAllUsers(pageNo, pageSize) {
  const response = await httpConnection.get(
    `user/getAllUsers?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchUsersData(values, pageNo, pageSize) {
  const data = {
    groupName: values.searchGroupName,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(`user/searchUser`, data, {
    headers: headers,
  });

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        userId: item.userid,
        username: item.username,
        password: item.password,
        name: item.name,
        emailId: item.email_id,
        mobileNo: item.mobile_no,
        designation: item.designation,
        employeeId: item.employee_id,

        groupId: item.group_id,
        groupName: item.group_name,
        reportingGroupId: item.reporting_group_id,
        reportingGroupName: item.reporting_group_name,
        reportingTo: item.reporting_to,
        reportingToName: item.reporting_to_name,

        status: item.active,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getUserListByGroup(groupId) {
  const response = await httpConnection.get(
    `user/getUserListByGroup?groupId=${groupId}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function loadGroupData(pageNo, pageSize) {
  const response = await httpConnection.get(
    `user/loadAllGroup?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function saveOrUpdateDistributorMaster(data) {
  const response = await httpConnection.post(
    `distributor-master/saveOrUpdateDistributorMaster`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllDistributorMasters(pageNo, pageSize) {
  const response = await httpConnection.get(
    `distributor-master/getAllDistributorMasters?pageNo=${pageNo}&pageSize=${pageSize}&groupId=${
      JSON.parse(sessionStorage.getItem("userData")).groupId
    }&username=${JSON.parse(sessionStorage.getItem("userData")).username}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchDistributorMaster(values, pageNo, pageSize) {
  const data = {
    subParty: values.searchSubParty,
    sapPartyCode: values.searchCustomerCode,

    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
    groupId: JSON.parse(sessionStorage.getItem("userData")).groupId,
    username: JSON.parse(sessionStorage.getItem("userData")).username,
  };
  const response = await httpConnection.post(
    `distributor-master/searchDistributorMaster`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        srNo: item.sr_no,
        distributorMasterId: item.distributor_master_id,
        regionMasterId: item.region_master_id,
        divisionMasterId: item.division_master_id,
        mainParty: item.main_party,
        subParty: item.sub_party,
        city: item.city,
        stateMasterId: item.state_master_id,
        sapPartyCode: item.sap_party_code,
        emailId: item.email_id,
        mobileNo: item.mobile_no,
        gstNo: item.gst_no,
        panNo: item.pan_no,
        remarks: item.remarks,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
        regionName: item.region_name,
        stateName: item.state_name,
        address: item.address,
        loginPin: item.login_pin,
        fcmId: item.fcm_id,
        passwordChangeDate: item.pwd_chg_dt,
        dixcyVirtualAc: item.dixcy_virtual_account,
        maximusVirtualAc: item.maximus_virtual_account,
        levisVirtualAc: item.levis_virtual_account,
        thermalVirtualAc: item.thermal_virtual_account,
        enamorVirtualAc: item.enamor_virtual_account,
        ifscCode: item.ifsc_code,
        clientCode: item.client_code,
        beneficiaryName: item.beneficiary_name,
        bankName: item.bank_name,
        bankBranch: item.bank_branch,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getAllOutstandingBills(
  customerCode,
  searchDate,
  pageNo,
  pageSize
) {
  const response = await httpConnection.get(
    `outstanding-bill/getAllOutStandingBill?customerCode=${customerCode}&searchDate=${searchDate}&pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchOutstandingBillData(values, pageNo, pageSize) {
  const data = {
    customerCode: values.searchCustomerCode,
    divisionName: values.searchDivisionName,
    invoiceNumber: values.searchInvoiceNumber,
    searchDate: moment(values.searchDate).format("YYYY-MM-DD"),

    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `outstanding-bill/searchOutstandingBill`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        srNo: item.sr_no,
        outstandingBillId: item.outstanding_bill_id,
        companyName: item.company_name,
        plantName: item.PLANT_NAME,
        plantCode: item.PLANT_CODE,
        customerCode: item.CUSTOMER_CODE,
        subAgent: item.SUB_AGENT,
        mainAgent: item.MAIN_AGENT,
        branchManger: item.BRANCH_MANGER,
        customerName: item.CUSTOMER_NAME,
        documentNumber: item.DOCUMENT_NUMBER,
        invoiceNumber: item.INVOICE_NUMBER,
        documentDate: item.DOCUMENT_DATE,
        invoiceAmount: item.INVOICE_AMOUNT,
        adjustedAMOUNT: item.ADJUSTED_AMOUNT,
        balanceAmount: item.BALANCE_AMOUNT,
        agingDAYS: item.AGING_DAYS,
        cDDueDate: item.CD_DUE_DATE,
        secondSlabDate: item.SECOND_SLAB_DATE,
        newBaselineDate: item.NEW_BASELINE_DATE,
        baselineDate: item.BASELINE_DATE,
        firstCDDays: item.FIRST_CD_DAYS,
        secondCDDays: item.SECOND_CD_DAYS,
        graceDays: item.GRACE_DAYS,
        cDExtensionDays: item.CD_EXTENSION_DAYS,
        cDAmount: item.CD_AMOUNT,
        cDPer: item.CD_PER,
        secondCDAmount: item.SECOND_CD_AMOUNT,
        secondCDPer: item.SECOND_CDPER,
        advanceType: item.ADVANCE_TYPE,
        billType: item.BILL_TYPE,
        advanceDiscountPer: item.ADVANCE_DISCOUNT_PER,
        disputeInvoiceStatus: item.DISPUTE_INVOICE_STATUS,
        customerMasterStatus: item.CUSTOMER_MASTER_STATUS,
        division: item.DIVISION,
        salesOffice: item.SALES_OFFICE,
        distributionChannel: item.DISTRIBUTION_CHANNEL,
        message: item.MESSAGE,

        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getAllOpenDebit(
  customerCode,
  pageNo,
  pageSize,
  searchDate
) {
  const response = await httpConnection.get(
    `open-debit/getAllOpenDebit?&customerCode=${customerCode}&pageNo=${pageNo}&pageSize=${pageSize}&searchDate=${searchDate}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchOpenDebitData(
  customerCode,
  values,
  pageNo,
  pageSize
) {
  const data = {
    customerCode: customerCode,
    customerName: values.searchCustomerName,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `open-debit/searchOpenDebit`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        openDebitId: item.open_debit_id,
        companyCode: item.company_code,
        companyName: item.company_name,
        customerCode: item.customer_code,
        customerName: item.customer_name,
        businessPlaceCode: item.business_place_code,
        businessPlaceName: item.business_place_name,
        subAgent: item.sub_agent,
        mainAgent: item.main_agent,
        managerName: item.manager_name,
        region: item.region,
        sdBillingDocNo: item.sd_billing_document_no,
        sdBillingDate: item.sd_billing_date,
        sdBillAmt: item.sd_billing_amount,
        documentType: item.document_type,
        debitNoteDocNo: item.debit_note_doc_no,
        debitNoteDocDate: item.debit_note_doc_date,
        postingDate: item.posting_date,
        debitNoteAmt: item.debit_note_amount,
        adjustedAmt: item.adjusted_amount,
        balanceDebit: item.balance_debit,
        agingDays: item.aging_days,
        shortText: item.short_text,
        longText: item.long_text,
        lrDate: item.lr_date,
        actualDueDate: item.actutal_due_date,
        brDate: item.br_date,
        paidDays: item.paid_days,
        delayDays: item.delay_days,
        expensesGlDesc: item.expenses_gl_desc,
        division: item.division,
        salesOffice: item.sales_office,
        distributionChannel: item.distribution_channel,

        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getAllOpenCredit(
  customerCode,
  searchDate,
  pageNo,
  pageSize
) {
  const response = await httpConnection.get(
    `open-credit/getAllOpenCredit?&customerCode=${customerCode}&pageNo=${pageNo}&pageSize=${pageSize}&searchDate=${searchDate}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchOpenCreditData(values, pageNo, pageSize) {
  const data = {
    customerCode: values.searchCustomerCode,
    customerName: values.searchCustomerName,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `open-credit/searchOpenCredit`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        openCreditId: item.open_credit_id,
        companyCode: item.company_code,
        companyName: item.company_name,
        customerCode: item.customer_code,
        customerName: item.customer_name,
        businessPlaceCode: item.business_place_code,
        businessPlaceName: item.business_place_name,
        subAgent: item.sub_agent,
        mainAgent: item.main_agent,
        branchManagerName: item.branch_manager_name,
        region: item.region,
        sdBillingNo: item.sd_billing_no,
        sdBillingDate: item.sd_billing_date,

        documentType: item.document_type,
        documentNumber: item.document_number,
        documentDate: item.document_date,
        postingDate: item.posting_date,
        cnAmt: item.cn_amount,
        adjustedAmt: item.adjusted_amount,
        balanceCnAmt: item.balance_cn_amount,
        agingDays: item.aging_days,
        shortText: item.short_text,
        longText: item.long_text,
        reference: item.reference,
        expensesGlDesc: item.expenses_gl_desc,
        division: item.division,
        salesOffice: item.sales_office,
        distributionChannel: item.distribution_channel,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getAllAdjustmentReport(customerCode, pageNo, pageSize) {
  const response = await httpConnection.get(
    `adjustment-report/getAllAdjustmentReport?&customerCode=${customerCode}&pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchAdjustmentReportData(
  selectedCustomerCode,
  values,
  pageNo,
  pageSize
) {
  const data = {
    customerCode: selectedCustomerCode,
    invoiceNumber: values.searchInvoiceNumber,
    financialYear: values.searchFinancialYear,
    month: values.searchMonth,
    duringMonth: values.searchDuringMonth,

    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `adjustment-report/searchAdjustmentReport`,
    data,
    {
      headers: headers,
    }
  );

  let totalCdCredit = 0;
  let totalCdNotEligible = 0;
  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      totalCdCredit += item.cd_credit;
      totalCdNotEligible += item.cd_not_eligible;
      return {
        adjustmentReportId:
          values.searchMonth === "DM"
            ? item.adjustment_report_id
            : item.adjustment_monthly_report_id,
        mainAgentName: item.main_agent_name,
        customerCode: item.customer_code,
        name: item.name,
        invoiceNo: item.invoice_no,
        invoiceDocNumber: item.invoice_doc_number,
        documentType: item.document_type,
        postingDate: item.posting_date,
        baselineDate: item.baseline_date,
        netDueDate: item.net_due_date,
        nddWithoutGraceDate: item.ndd_without_grace_date,
        beforeTaxValue: item.before_tax_value,
        totalInvoiceAmt: item.total_invoice_amt,
        division: item.division,
        divisionDescription: item.division_description,
        clearDocNo: item.clear_doc_no,
        clearingDate: item.clearing_date,
        cdApplicableAmount: item.cd_applicable_amount,
        toBeReceived: item.to_be_received,
        documentNo: item.document_no,
        voucherType: item.voucher_type,
        description: item.description,
        receiptCn: item.receipt_cn,
        balanceExcess: item.balance_excess,
        remainDocument: item.remain_document,
        remainAmts: item.remain_amts,
        receiptCNDate: item.receipt_cn_date,
        paidDay: item.paid_day,
        cdCredit: item.cd_credit,
        cdProvision: item.cd_provision,
        cdNotEligible: item.cd_not_eligible,
        payTermWithGraceDays: item.pay_term_with_grace_days,
        payTerm: item.pay_term,
        actualCDPercentage: item.actual_cd_percentage,
        delaydays: item.delay_days,

        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,

        totalInvoiceAmtNew: item.total_invoice_amt_new,
        receiptCnNew: item.receipt_cn_new,
        cdReceivedAmtNew: item.cd_received_amt_new,
        balanceExcessNew: item.balance_excess_new,
        payTerm1WithGraceDays: item.pay_term1_with_grace_days,
        payTerm1: item.pay_term1,
        slab1ActualCdPercentage: item.slab1_actual_cd_percentage,
        payTerm2WithGraceDays: item.pay_term2_with_grace_days,
        payTerm2: item.pay_term2,
        slab2ActualCdPercentage: item.slab2_actual_cd_percentage,
        payTerm3WithGraceDays: item.pay_term3_with_grace_days,
        payTerm3: item.pay_term3,
        slab3ActualCdPercentage: item.slab3_actual_cd_percentage,
        payTerm4WithGraceDays: item.pay_term4_with_grace_days,
        payTerm4: item.pay_term4,
        slab4ActualCdPercentage: item.slab4_actual_cd_percentage,
        payTerm5WithGraceDays: item.pay_term5_with_grace_days,
        payTerm5: item.pay_term5,
        slab5ActualCdPercentage: item.slab5_actual_cd_percentage,
        cdReceivedRevisedAmount: item.cd_received_revised_amt,
        revisedDate: item.revised_date,
      };
    }
  );
  if (values.searchMonth !== "DM") {
    response.data.responseMap.data = [
      ...parsedResponse,
      {
        cdCredit: totalCdCredit,
        cdNotEligible: totalCdNotEligible,
      },
    ];
  } else {
    response.data.responseMap.data = [...parsedResponse];
  }
  return response.data;
}

export async function getGroupUserForCombo(pageNo, pageSize) {
  const response = await httpConnection.get(
    `user/getGroupUserForCombo?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function updateDistributorMasterBasedOnRegionBM(data) {
  const response = await httpConnection.put(
    `distributor-master/updateDistributorMasterBasedOnRegionBM`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getReasonCombo(pageNo, pageSize) {
  const response = await httpConnection.get(
    `common/getReasonCombo?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function saveOrUpdateCreditExtension(data) {
  const response = await httpConnection.post(
    `credit-extension/saveOrUpdateCreditExtension`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllCreditExtension(pageNo, pageSize) {
  const response = await httpConnection.get(
    `credit-extension/getAllCreditExtension?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchCreditExtensionData(values, pageNo, pageSize) {
  const data = {
    customerCode: values.searchCustomerCode,
    approvalStatus: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `credit-extension/searchCreditExtension`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        creditExtensionId: item.credit_extension_id,
        customerCode: item.sap_customer_code,
        invoiceNumber: item.invoice_no,
        documentDate: item.invoice_date,
        distributorId: item.distributor_id,
        cdextensionDays: item.credit_extension_days,
        reason: item.reason,
        otherReason: item.reason_others,
        remarks: item.remarks,
        approvalStatus: item.status,
        approvalRemarks: item.approval_remarks,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
        distributorName: item.distributorName,
        approvedDays: item.approved_days,
        sendNotify: item.notify,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getAllBalanceConfirmation(pageNo, pageSize) {
  const response = await httpConnection.get(
    `balance-confirmation/getAllBalanceConfirmation?pageNo=${pageNo}&pageSize=${pageSize}&groupId=${
      JSON.parse(sessionStorage.getItem("userData")).groupId
    }&username=${JSON.parse(sessionStorage.getItem("userData")).username}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchBalanceConfirmationData(values, pageNo, pageSize) {
  const data = {
    customerCode: values.searchCustomerCode,
    region: values.searchRegion,
    state: values.searchState,
    balanceConfirmationStatus: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
    groupId: JSON.parse(sessionStorage.getItem("userData")).groupId,
    username: JSON.parse(sessionStorage.getItem("userData")).username,
  };
  const response = await httpConnection.post(
    `balance-confirmation/searchBalanceConfirmation`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        balanceConfirmationId: item.balance_confirmation_id,
        customerCode: item.customer_code,
        closingBalance: item.closing_balance,
        balanceConfirmationDate: item.balance_confirmation_date,
        balanceConfirmationType: item.balance_confirmation_type,
        agreedDate: item.agreed_date,
        balanceConfirmationStatus: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
        name: item.sub_party,
        regionName: item.region_name,
        stateName: item.state_name,
        customerBalanceAmount:
          item.status === "Agreed"
            ? item.closing_balance
            : item.customer_balance_amount,
        customerBalanceType: item.customer_balance_type,
        financialYear: item.financial_year,
        quarter: item.quarter,
        filePath: item.file_path,
        revisionNo: item.revision_no,
        oldRevisionNo: item.revision_no,
        claimDetails: [],
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function saveOrUpdatePolicyMaster(data) {
  const response = await httpConnection.post(
    `policy-master/saveOrUpdatePolicyMaster`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllPolicyMaster(pageNo, pageSize, customerCode) {
  const response = await httpConnection.get(
    `policy-master/getAllPolicyMaster?pageNo=${pageNo}&pageSize=${pageSize}&customerCode=${customerCode}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchPolicyData(values, pageNo, pageSize) {
  const data = {
    policyName: values.searchPolicyName,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `policy-master/searchPolicyMaster`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        policyMasterId: item.policy_master_id,
        policyName: item.policy_name,
        description: item.description,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getBalanceConfirmationDtlByCustomerCode(
  customerCode,
  pageNo,
  pageSize
) {
  const response = await httpConnection.get(
    `balance-confirmation/getBalanceConfirmationDtl?customerCode=${customerCode}&pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function saveOrUpdateReasonMaster(data) {
  const response = await httpConnection.post(
    `common/saveOrUpdateReasonMaster`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllReasonMasters(pageNo, pageSize) {
  const response = await httpConnection.get(
    `common/getAllReasonMaster?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchReasonData(values, pageNo, pageSize) {
  const data = {
    moduleType: values.searchModuleType,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `common/searchReasonMaster`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        commonId: item.common_reason_id,
        reason: item.reason,
        moduleType: item.moldule_type,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function saveOrUpdateAddressMaster(data) {
  const response = await httpConnection.post(
    `address-master/saveOrUpdateAddressMaster`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllAddressMaster(pageNo, pageSize) {
  const response = await httpConnection.get(
    `address-master/getAllAddressMaster?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchAddressData(values, pageNo, pageSize) {
  const data = {
    plantCode: values.searchPlantCode,
    location: values.searchLocation,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `address-master/searchAddressMaster`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        addressMasterId: item.address_master_id,
        plantCode: item.plant_code,
        plantName: item.plant_name,
        location: item.location,
        gst: item.gst,
        address: item.address,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function exportToExcelOutstanding(customerCode, dateTime) {
  try {
    const response = await httpConnection.get(
      `outstanding-bill/export-to-excel?customerCode=${customerCode}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Outstanding_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToExcelOpenDebit(customerCode, dateTime) {
  try {
    const response = await httpConnection.get(
      `open-debit/export-to-excel?customerCode=${customerCode}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OpenDebit_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToExcelOpenCredit(customerCode, dateTime) {
  try {
    const response = await httpConnection.get(
      `open-credit/export-to-excel?customerCode=${customerCode}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OpenCredit_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToExcelAdjustmentReport(
  customerCode,
  dateTime,
  values
) {
  try {
    const response = await httpConnection.get(
      `adjustment-report/export-to-excel?customerCode=${customerCode}&dateTime=${dateTime}&financialYear=${values.searchFinancialYear}&month=${values.searchMonth}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `AdjustmentReport_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToExcelCreditExtension(customerCode, dateTime) {
  try {
    const response = await httpConnection.get(
      `credit-extension/export-to-excel?customerCode=${customerCode}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `CreditExtension_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToExcelBalanceConfirmation(customerCode, dateTime) {
  try {
    const response = await httpConnection.get(
      `balance-confirmation/export-to-excel?customerCode=${customerCode}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `BalanceConfirmation_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToPDFOpenCredit(
  customerCode,
  openCreditDTO,
  dateTime
) {
  try {
    const response = await httpConnection.get(
      `open-credit/export-to-pdf?customerCode=${customerCode}&openCreditId=${openCreditDTO.openCreditId}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OpenCredit_report_${dateTime}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  }
}

export async function exportToPDFOpenDebit(
  customerCode,
  openDebitDTO,
  dateTime
) {
  try {
    const response = await httpConnection.get(
      `open-debit/export-to-pdf?customerCode=${customerCode}&openDebitId=${openDebitDTO.openDebitId}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OpenDebit_report_${dateTime}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  }
}

export async function exportToPDFBalanceConfirmation(
  customerCode,
  balanceConfirmationDTO,
  dateTime
) {
  try {
    const response = await httpConnection.get(
      `balance-confirmation/export-to-pdf?customerCode=${customerCode}&balanceConfirmationId=${balanceConfirmationDTO.balanceConfirmationId}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `BalanceConfirmation_report_${dateTime}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  }
}

export async function saveOrUpdateDistributorMasterDtl(data) {
  const response = await httpConnection.post(
    `distributor-master/saveOrUpdateDistributorMasterDtl`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getAllDistributorMastersMapping(pageNo, pageSize) {
  const response = await httpConnection.get(
    `distributor-master/getAllDistributorMastersMapping?pageNo=${pageNo}&pageSize=${pageSize}&groupId=${
      JSON.parse(sessionStorage.getItem("userData")).groupId
    }&username=${JSON.parse(sessionStorage.getItem("userData")).username}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function searchDistributorMasterMapping(values, pageNo, pageSize) {
  const data = {
    sapPartyCode: values.searchCustomerCode,
    groupId: JSON.parse(sessionStorage.getItem("userData")).groupId,
    username: JSON.parse(sessionStorage.getItem("userData")).username,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `distributor-master/searchDistributorMasterMapping`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        srNo: item.sr_no,
        distributorMasterDtlId: item.distributor_master_dtl_id,
        distributorMasterId: item.distributor_master_id,
        mainAgent: item.main_agent,
        subAgent: item.sub_agent,
        bm: item.bm,
        bmName: item.bm_name,
        bmEmail: item.bm_email,
        bsm: item.bsm,
        asm: item.asm,
        coOrdinatorOne: item.co_ordinator_1,
        coOrdinatorOneName: item.co_ordinator_1_name,
        coOrdinatorOneEmail: item.co_ordinator_1_email,
        coOrdinatorTwo: item.co_ordinator_2,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
        divisionName: item.division_name,
        sapPartyCode: item.sap_party_code,
        ssm: item.ssm,
        ssmName: item.ssm_name,
        ssmEmail: item.ssm_email,
        bce: item.bce,
        bceName: item.bce_name,
        bceEmail: item.bce_email,
        bcm: item.bcm,
        bcmName: item.bcm_name,
        bcmEmail: item.bcm_email,
        bsmName: item.bsm_name,
        bsmEmail: item.bsm_email,
        asmName: item.asm_name,
        asmEmail: item.asm_email,
        coOrdinatorTwoName: item.co_ordinator_2_name,
        coOrdinatorTwoEmail: item.co_ordinator_2_email,
        asmLoginId: item.asm_login_id,
        ssmLoginId: item.ssm_login_id,
        bceMobileNo: item.bce_mobile_no,
        bcmMobileNo: item.bcm_mobile_no,
        paymentTerms: item.payment_terms,
      };
    }
  );
  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function getAllBalanceConfirmationReport() {
  const response = await httpConnection.get(
    `balance-confirmation/getBalanceConfirmationConsolidateReport`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getBalanceConfirmationConsolidateReportByRegion(
  regionMasterId
) {
  const response = await httpConnection.get(
    `balance-confirmation/getBalanceConfirmationConsolidateReportByRegion?regionMasterId=${regionMasterId}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function exportToExcelBalanceConfirmationConsolidate() {
  try {
    const response = await httpConnection.get(
      `balance-confirmation/export-to-excel-consolidate`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `BalanceConfirmation_Consolidate_report.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function getAllDistributorMastersCombo() {
  const response = await httpConnection.get(
    `distributor-master/getAllDistributorMastersCombo`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getAllDistributorMastersComboByLoginUser() {
  const response = await httpConnection.get(
    `distributor-master/getAllDistributorMastersComboByLoginUser?groupId=${
      JSON.parse(sessionStorage.getItem("userData")).groupId
    }&username=${JSON.parse(sessionStorage.getItem("userData")).username}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function saveBalanceConfirmationMobile(data) {
  const response = await httpConnection.post(
    `balance-confirmation/saveBalanceConfirmationMobile`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function updateLoginPin(loginUserId, password) {
  const response = await httpConnection.put(
    `distributor-master/updateLoginPin`,
    {
      distributorMasterId: loginUserId,
      loginPin: password,
    },
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function changePassword(loginUserId, password) {
  const response = await httpConnection.put(
    `user/changePassword`,
    {
      userId: loginUserId,
      password: password,
    },
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function getBalanceConfirmationDtlByHdrId(balanceConfirmationId) {
  const response = await httpConnection.get(
    `balance-confirmation/getBalanceConfirmationDtlByHdrId?balanceConfirmationId=${balanceConfirmationId}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getLoggedInUserDetails(pageNo, pageSize) {
  const response = await httpConnection.get(
    `common/getLoggedInUserDetails?pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function resetBalanceConfirmation(data) {
  const response = await httpConnection.post(
    `balance-confirmation/resetBalanceConfirmation`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function searchLoggedInUserDetails(values, pageNo, pageSize) {
  const data = {
    groupId: values.searchGroup,
    searchFromDate: values.searchFromDate,
    searchToDate: values.searchToDate,
    pageNumber: pageNo,
    pageSize: pageSize,
  };
  const response = await httpConnection.post(
    `common/searchLoggedInUserDetails`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        loginInfoId: item.login_info_id,
        name: item.name,
        loginUserName: item.login_user_name,
        groupId: item.group_id,
        groupName: item.group_name,
        loginTime: item.login_time,
        logoutTime: item.logout_time,
        loginType: item.login_type,
        stateName: item.state_name,
      };
    }
  );
  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function exportToExcelUserLog(values, dateTime) {
  try {
    const data = {
      groupId: values.searchGroup,
      searchFromDate: values.searchFromDate,
      searchToDate: values.searchToDate,
      fileDateTime: dateTime,
    };
    const response = await httpConnection.post(
      `common/export-to-excel-userlog`,
      data,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `User_Log_Details${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function getDistributorInfoByCustomerCode(customerCode) {
  const response = await httpConnection.get(
    `distributor-master/getDistributorInfoByCustomerCode?customerCode=${customerCode}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getAllOpenCreditHistory(customerCode) {
  const response = await httpConnection.get(
    `open-credit/getAllOpenCreditHistory?&customerCode=${customerCode}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getAllOpenDebitHistory(customerCode) {
  const response = await httpConnection.get(
    `open-debit/getAllOpenDebitHistory?&customerCode=${customerCode}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getAllOutstandingBillNew(
  customerCode,
  searchDate,
  pageNo,
  pageSize
) {
  const response = await httpConnection.get(
    `outstanding-bill/getAllOutstandingBillNew?customerCode=${customerCode}&searchDate=${searchDate}&pageNo=${pageNo}&pageSize=${pageSize}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getAllTds194RDeclaration(pageNo, pageSize) {
  const response = await httpConnection.get(
    `tds-declaration/getAllTds194RDeclaration?pageNo=${pageNo}&pageSize=${pageSize}&groupId=${
      JSON.parse(sessionStorage.getItem("userData")).groupId
    }&username=${JSON.parse(sessionStorage.getItem("userData")).username}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function saveTds194RDeclaration(data) {
  const response = await httpConnection.post(
    `tds-declaration/saveTds194RDeclaration`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function searchTds194RDeclaration(values, pageNo, pageSize) {
  const data = {
    customerCode: values.searchCustomerCode,
    region: values.searchRegion,
    state: values.searchState,
    status: values.searchStatus,
    pageNumber: pageNo,
    pageSize: pageSize,
    groupId: JSON.parse(sessionStorage.getItem("userData")).groupId,
    username: JSON.parse(sessionStorage.getItem("userData")).username,
  };
  const response = await httpConnection.post(
    `tds-declaration/searchTds194RDeclaration`,
    data,
    {
      headers: headers,
    }
  );

  const parsedResponse = response.data.responseMap.SEARCH_FORM_LIST.map(
    (item, pos) => {
      return {
        tdsDeclarationId: item.tds_194r_declaration_id,
        customerCode: item.customer_code,
        financialYear: item.financial_year,
        agreedDate: item.agreed_date,
        status: item.status,
        createdBy: item.created_by,
        createdDate: item.created_dt,
        updatedBy: item.updated_by,
        updatedDate: item.updated_dt,
        name: item.sub_party,
        regionName: item.region_name,
        stateName: item.state_name,
      };
    }
  );

  response.data.responseMap.data = [...parsedResponse];
  return response.data;
}

export async function resetTds194RDeclaration(data) {
  const response = await httpConnection.post(
    `tds-declaration/resetTds194RDeclaration`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}

export async function exportToPDFTdsDeclaration(
  customerCode,
  tds194rDeclarationDTO,
  dateTime
) {
  try {
    const response = await httpConnection.get(
      `tds-declaration/export-to-pdf?customerCode=${customerCode}&tdsDeclarationId=${tds194rDeclarationDTO.tdsDeclarationId}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `194R_TDS_Declaration_report_${dateTime}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  }
}

export async function getTds194RDeclarationConsolidateReport() {
  const response = await httpConnection.get(
    `tds-declaration/getTds194RDeclarationConsolidateReport`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getTds194RDeclarationConsolidateReportByRegion(
  regionMasterId
) {
  const response = await httpConnection.get(
    `tds-declaration/getTds194RDeclarationConsolidateReportByRegion?regionMasterId=${regionMasterId}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function exportToExcelTds194RDeclaration(customerCode, dateTime) {
  try {
    const response = await httpConnection.get(
      `tds-declaration/export-to-excel?customerCode=${customerCode}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Tds194RDeclaration_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToExcelTds194RDeclarationConsolidate() {
  try {
    const response = await httpConnection.get(
      `tds-declaration/export-to-excel-consolidate`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `194R_TDS_Declaration_Consolidate_report.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function getAllLedger(customerCode, pageNo, pageSize, searchDate) {
  const response = await httpConnection.get(
    `ledger/getAllLedger?&customerCode=${customerCode}&pageNo=${pageNo}&pageSize=${pageSize}&searchDate=${searchDate}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function exportToExcelLedger(
  customerCode,
  customerName,
  dateTime
) {
  try {
    const response = await httpConnection.get(
      `ledger/export-to-excel?customerCode=${customerCode}&customerName=${customerName}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Ledger_report_${dateTime}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
}

export async function exportToPDFLedger(customerCode, customerName, dateTime) {
  try {
    const response = await httpConnection.get(
      `ledger/export-to-pdf?customerCode=${customerCode}&customerName=${customerName}&dateTime=${dateTime}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Ledger_report_${dateTime}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  }
}

export async function getAllBalanceConfirmationHistory(balanceConfirmationId) {
  const response = await httpConnection.get(
    `balance-confirmation/getAllBalanceConfirmationHistory?balanceConfirmationId=${balanceConfirmationId}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getConsolidateAdjustmentMonthlyReport(
  customerCode,
  financialYear
) {
  const response = await httpConnection.get(
    `adjustment-report/getConsolidateAdjustmentMonthlyReport?&customerCode=${customerCode}&financialYear=${financialYear}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function getLoggedInUserCount(groupId) {
  const response = await httpConnection.get(
    `common/getLoggedInUserCount?groupId=${groupId}`,
    {},
    { headers: headers }
  );
  return {
    response: response.data,
  };
}

export async function updateRevisedCdAmount(
  searchMonth,
  adjustmentReportId,
  cdReceivedRevisedAmount
) {
  const data = {
    month: searchMonth,
    adjustmentReportId: adjustmentReportId,
    cdReceivedRevisedAmount: cdReceivedRevisedAmount,
  };
  const response = await httpConnection.post(
    `adjustment-report/updateRevisedCdAmount`,
    data,
    {
      headers: headers,
    }
  );
  return {
    response: response.data,
  };
}
