import { FileCopySharp } from "@mui/icons-material";
import {
  Home,
  Box,
  Clipboard,
  Camera,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
} from "react-feather";

export const ADMINMENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  /* {
        title: 'Products', icon: Box, type: 'sub', active: false, children: [
            {
                title: 'Physical', type: 'sub', active: false, children: [
                    { path: '/products/physical/category', title: 'Category', type: 'link' },
                    { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
                    { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
                    { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
                ]
            },
            {
                title: 'digital', type: 'sub', active: false, children: [
                    { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
                    { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
                    { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
                ]
            },
        ]
    }, */

  {
    title: "User Management",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      { path: "/userManagement/users", title: "User", type: "link" },
      { path: "/userManagement/group", title: "Group", type: "link" },
      { path: "/userManagement/userLog", title: "User Log", type: "link" },
    ],
  },
  {
    title: "Master",
    icon: FileCopySharp,
    type: "sub",
    active: false,
    children: [
      { path: "/master/regionMaster", title: "Region Master", type: "link" },
      { path: "/master/stateMaster", title: "State Master", type: "link" },
      {
        path: "/master/divisionMaster",
        title: "Division Master",
        type: "link",
      },
      {
        path: "/master/distributorMaster",
        title: "Distributor Master",
        type: "link",
      },

      {
        path: "/master/distributorMapping",
        title: "Distributor Mapping",
        type: "link",
      },
      {
        path: "/master/policyMaster",
        title: "Policy Master",
        type: "link",
      },

      {
        path: "/master/reasonMaster",
        title: "Reason",
        type: "link",
      },
      {
        path: "/master/addressMaster",
        title: "Address Master",
        type: "link",
      },
    ],
  },

  /*  {
        title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
            { path: '/pages/list-page', title: 'List Page', type: 'link' },
            { path: '/pages/create-page', title: 'Create Page', type: 'link' },
        ]
    }, */
  // {
  //     title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
  // },

  // {
  //     title: 'Users', icon: UserPlus, type: 'sub', active: false, children: [
  //         { path: '/users/list-user', title: 'User List', type: 'link' },
  //         { path: '/users/create-user', title: 'Create User', type: 'link' },
  //     ]
  // },

  // {
  //     title: 'Localization', icon: Chrome, type: 'sub', children: [
  //         { path: '/localization/transactions', title: 'Translations', type: 'link' },
  //         { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
  //         { path: '/localization/taxes', title: 'Taxes', type: 'link' }
  //     ]
  // },

  //TRANSACTION

  {
    title: "Transaction",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/master/creditExtension",
        title: "Credit Extension",
        type: "link",
      },
      {
        path: "/master/ndc",
        title: "NDC",
        type: "link",
      },
    ],
  },

  {
    title: "Reports",
    //path: "/reports/report",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/master/balanceConfirmation",
        title: "Balance Confirmation",
        type: "link",
      },
      {
        path: "/reports/outstandingBill",
        title: "Outstanding Bill",
        type: "link",
      },

      {
        path: "/reports/openDebit",
        title: "Open Debit",
        type: "link",
      },

      {
        path: "/reports/openCredit",
        title: "Open Credit",
        type: "link",
      },

      {
        path: "/reports/adjustmentReport",
        title: "Adjustment Report",
        type: "link",
      },
      {
        path: "/reports/ledger",
        title: "Ledger",
        type: "link",
      },
      {
        path: "/master/tds194RDeclaration",
        title: "194R TDS Declaration",
        type: "link",
      },
    ],
  },
  // {
  //     title: 'Settings', icon: Settings, type: 'sub', children: [
  //         { path: '/settings/profile', title: 'Profile', type: 'link' },
  //     ]
  // },
];

export const ASMMENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  //TRANSACTION
  {
    title: "Reports",
    //path: "/reports/report",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/master/balanceConfirmation",
        title: "Balance Confirmation",
        type: "link",
      },
      {
        path: "/reports/outstandingBill",
        title: "Outstanding Bill",
        type: "link",
      },

      {
        path: "/reports/openDebit",
        title: "Open Debit",
        type: "link",
      },

      {
        path: "/reports/openCredit",
        title: "Open Credit",
        type: "link",
      },

      {
        path: "/reports/adjustmentReport",
        title: "Adjustment Report",
        type: "link",
      },
      {
        path: "/reports/ledger",
        title: "Ledger",
        type: "link",
      },
      {
        path: "/master/tds194RDeclaration",
        title: "194R TDS Declaration",
        type: "link",
      },
    ],
  },
];

export const DISTRIBUTORMENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },

  //TRANSACTION
  {
    title: "Transaction",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/master/balanceConfirmation",
        title: "Balance Confirmation",
        type: "link",
      },
      {
        path: "/master/tds194RDeclaration",
        title: "194R TDS Declaration",
        type: "link",
      },
    ],
  },

  {
    title: "Reports",
    //path: "/reports/report",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/reports/outstandingBill",
        title: "Outstanding Bill",
        type: "link",
      },

      {
        path: "/reports/openDebit",
        title: "Open Debit",
        type: "link",
      },

      {
        path: "/reports/openCredit",
        title: "Open Credit",
        type: "link",
      },

      {
        path: "/reports/adjustmentReport",
        title: "Adjustment Report",
        type: "link",
      },
      {
        path: "/reports/ledger",
        title: "Ledger",
        type: "link",
      },
    ],
  },
];

export const CEO_CFO_CSO_MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Reports",
    //path: "/reports/report",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/master/balanceConfirmation",
        title: "Balance Confirmation",
        type: "link",
      },
      {
        path: "/reports/outstandingBill",
        title: "Outstanding Bill",
        type: "link",
      },

      {
        path: "/reports/openDebit",
        title: "Open Debit",
        type: "link",
      },

      {
        path: "/reports/openCredit",
        title: "Open Credit",
        type: "link",
      },

      {
        path: "/reports/adjustmentReport",
        title: "Adjustment Report",
        type: "link",
      },
      {
        path: "/reports/ledger",
        title: "Ledger",
        type: "link",
      },
      {
        path: "/master/tds194RDeclaration",
        title: "194R TDS Declaration",
        type: "link",
      },
    ],
  },
];

export const FH_MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Reports",
    //path: "/reports/report",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/master/balanceConfirmation",
        title: "Balance Confirmation",
        type: "link",
      },
      {
        path: "/reports/outstandingBill",
        title: "Outstanding Bill",
        type: "link",
      },

      {
        path: "/reports/openDebit",
        title: "Open Debit",
        type: "link",
      },

      {
        path: "/reports/openCredit",
        title: "Open Credit",
        type: "link",
      },

      {
        path: "/reports/adjustmentReport",
        title: "Adjustment Report",
        type: "link",
      },
      {
        path: "/reports/ledger",
        title: "Ledger",
        type: "link",
      },
      {
        path: "/master/tds194RDeclaration",
        title: "194R TDS Declaration",
        type: "link",
      },
      { path: "/userManagement/userLog", title: "User Log", type: "link" },
    ],
  },
];

export const TDS_USER_MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Reports",
    //path: "/reports/report",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/master/tds194RDeclaration",
        title: "194R TDS Declaration",
        type: "link",
      },
    ],
  },
];
