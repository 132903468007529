import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, Col, Container, Row, Media } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  exportToExcelUserLog,
  getLoggedInUserCount,
  getLoggedInUserDetails,
  loadGroupData,
  searchLoggedInUserDetails,
} from "../../api/service";
import CommonTable from "../../common/commonTable";
import {
  userLogTableData,
  userLogTableHeader,
} from "../../common/commonConstant";
import CommonLoader from "../../common/commonLoader";
import CommonAccordion from "../../common/commonAccordion";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import {
  PersonSearch,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import moment from "moment";
import { Chart } from "react-google-charts";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const UserLog = () => {
  const classes = useStyles();

  const filterInitialValues = {
    searchGroup: -1,
    searchFromDate: "",
    searchToDate: "",
    searchDaysType: "Today",
  };
  const [searchData, setSearchData] = useState(filterInitialValues);
  const [isSearch, setIsSearch] = useState(false);
  //const [groupData, setGroupData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [data, setData] = useState([]);
  const [userReportList, setUserReportList] = useState([]);

  const [androidCount, setAndroidCount] = useState(0);
  const [iosCount, setIosCount] = useState(0);
  const [webCount, setWebCount] = useState(0);
  const [allCount, setAllCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const pieOptions = {
    title: "",
    pieHole: 1,
    slices: [
      {
        color: "#ff8084",
      },
      {
        color: "#13c9ca",
      },
      {
        color: "#f0b54d",
      },
      /* {
        color: "#36c478",
      }, */
    ],
    tooltip: {
      showColorCode: false,
    },
    //backgroundColor: "transparent",
    chartArea: { width: "360px", height: "100%" },
    //chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    legend: "none",
  };
  useEffect(() => {
    loadListData(page, rowsPerPage);
    //loadGroupList();
  }, []);

  useEffect(() => {
    getReportCount(searchData.searchDaysType);
  }, [userReportList]);

  const loadListData = (page, rowsPerPage) => {
    setIsSearch(false);
    setLoading(true);
    getLoggedInUserCount(searchData.searchGroup).then((res) => {
      setUserReportList(res.response.responseObject);
    });
    getLoggedInUserDetails(page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const getReportCount = (type = "") => {
    if (!isEmpty(userReportList)) {
      const androidData = userReportList.find(
        (item) => item.loginType === "Android"
      );
      const iosData = userReportList.find((item) => item.loginType === "iOS");
      const webData = userReportList.find((item) => item.loginType === "Web");
      const allData = userReportList.find((item) => item.loginType === "ALL");

      if (type === "Last 7 Days") {
        setAndroidCount(androidData.count7Days);
        setIosCount(iosData.count7Days);
        setWebCount(webData.count7Days);
        setAllCount(allData.count7Days);
      } else if (type === "Last 10 Days") {
        setAndroidCount(androidData.count10Days);
        setIosCount(iosData.count10Days);
        setWebCount(webData.count10Days);
        setAllCount(allData.count10Days);
      } else if (type === "Last 30 Days") {
        setAndroidCount(androidData.count30Days);
        setIosCount(iosData.count30Days);
        setWebCount(webData.count30Days);
        setAllCount(allData.count30Days);
      } else {
        setAndroidCount(androidData.countToday);
        setIosCount(iosData.countToday);
        setWebCount(webData.countToday);
        setAllCount(allData.countToday);
      }
    }
  };

  const loadGroupList = () => {
    /* loadGroupData(page, 100).then((res) => {
      setGroupData(res.response.responseObject);
    }); */
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isSearch) {
      filterData(newPage, rowsPerPage);
    } else {
      loadListData(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    if (isSearch) {
      filterData(0, event.target.value);
    } else {
      loadListData(0, event.target.value);
    }
  };

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
    if (name === "searchDaysType") {
      getReportCount(value);
    }
  };

  const filterData = (page, rowsPerPage) => {
    setIsSearch(true);
    setLoading(true);
    getLoggedInUserCount(searchData.searchGroup).then((res) => {
      setUserReportList(res.response.responseObject);
    });
    searchLoggedInUserDetails(searchData, page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.responseMap.data);
      setTotalRecord(res.responseMap.totalCount);
    });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelUserLog(
      searchData,
      moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
    ).then(() => {
      setLoading(false);
    });
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="User Log" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Col lg={6} xs={12}>
            <CommonAccordion accordionTitle={"Advanced Search"}>
              <Grid
                container
                spacing={1.5}
                columnSpacing={2}
                className={classes.muiGrid}
              >
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-select-currency"
                    select
                    label="Group"
                    size="small"
                    defaultValue="Y"
                    name="searchGroup"
                    value={searchData.searchGroup}
                    onChange={onFilterInputChange}
                  >
                    <MenuItem key={-1} value={-1}>
                      {"ALL"}
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                      {"DISTRIBUTOR"}
                    </MenuItem>
                    <MenuItem key={0} value={1}>
                      {"MODENIK TEAM"}
                    </MenuItem>
                    {/* {groupData.map((option) => (
                      <MenuItem key={option.groupId} value={option.groupId}>
                        {option.groupName}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label="From Date"
                    size="small"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="fullWidth"
                    name="searchFromDate"
                    value={searchData.searchFromDate}
                    onChange={onFilterInputChange}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label="To Date"
                    size="small"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="fullWidth"
                    name="searchToDate"
                    value={searchData.searchToDate}
                    onChange={onFilterInputChange}
                  />
                </Grid>

                {/*  <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-select-currency"
                    select
                    label="Days"
                    size="small"
                    defaultValue="Y"
                    name="searchDaysType"
                    value={searchData.searchDaysType}
                    onChange={onFilterInputChange}
                  >
                    <MenuItem key={"Today"} value={"Today"}>
                      {"Today"}
                    </MenuItem>
                    <MenuItem key={"Last 7 Days"} value={"Last 7 Days"}>
                      {"Last 7 Days"}
                    </MenuItem>
                    <MenuItem key={"Last 10 Days"} value={"Last 10 Days"}>
                      {"Last 10 Days"}
                    </MenuItem>
                    <MenuItem key={"Last 30 Days"} value={"Last 30 Days"}>
                      {"Last 30 Days"}
                    </MenuItem>
                  </TextField>
                </Grid> */}

                <Grid
                  item
                  sm={6}
                  xs={12}
                  sx={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    className={classes.btnClass}
                    onClick={() => filterData(page, rowsPerPage)}
                  >
                    <PersonSearch></PersonSearch>
                  </Button>
                  <Button
                    color="primary"
                    sx={{ marginLeft: 1 }}
                    variant="contained"
                    type="button"
                    className={classes.btnClass}
                    onClick={() => {
                      setSearchData(filterInitialValues);
                      loadListData(0, 25);
                    }}
                  >
                    <Refresh></Refresh>
                  </Button>

                  <Button
                    color="primary"
                    sx={{ marginLeft: 1 }}
                    variant="contained"
                    type="button"
                    className={classes.btnClass}
                    onClick={() => {
                      exportData();
                    }}
                  >
                    <SimCardDownloadRounded></SimCardDownloadRounded>
                  </Button>
                </Grid>
              </Grid>
            </CommonAccordion>
          </Col>
          <Col lg={6} xs={12}>
            <Grid
              container
              spacing={1.5}
              columnSpacing={2}
              className={classes.muiGrid}
              style={{ background: "#fff" }}
            >
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Days"
                  size="small"
                  defaultValue="Y"
                  name="searchDaysType"
                  value={searchData.searchDaysType}
                  onChange={onFilterInputChange}
                >
                  <MenuItem key={"Today"} value={"Today"}>
                    {"Today"}
                  </MenuItem>
                  <MenuItem key={"Last 7 Days"} value={"Last 7 Days"}>
                    {"Last 7 Days"}
                  </MenuItem>
                  <MenuItem key={"Last 10 Days"} value={"Last 10 Days"}>
                    {"Last 10 Days"}
                  </MenuItem>
                  <MenuItem key={"Last 30 Days"} value={"Last 30 Days"}>
                    {"Last 30 Days"}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item sm={6} xs={12}></Grid>
              <Grid item sm={6} xs={12}>
                <div className="order-graph sm-order-space">
                  <div className="peity-chart-dashboard text-center">
                    <Chart
                      chartType="PieChart"
                      data={[
                        ["Type", "Login Count"],
                        ["Android", androidCount],
                        ["iOS", iosCount],
                        ["Web", webCount],
                        //["All", allCount],
                      ]}
                      options={pieOptions}
                      graph_id="PieChart"
                      width={"100%"}
                      height={"z80px"}
                      s
                      legend_toggle
                    />
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} paddingRight={5}>
                <div className="order-graph sm-order-space">
                  <div className="order-graph-bottom sales-location">
                    <Media>
                      <div className="order-shape-primary"></div>
                      <Media body>
                        <h6 className="mb-0 me-0">
                          Android{" "}
                          <span className="pull-right">{androidCount}</span>
                        </h6>
                      </Media>
                    </Media>
                    <Media>
                      <div className="order-shape-secondary"></div>
                      <Media body>
                        <h6 className="mb-0 me-0">
                          iOS <span className="pull-right">{iosCount}</span>
                        </h6>
                      </Media>
                    </Media>
                    <Media>
                      <div className="order-shape-warning"></div>
                      <Media body>
                        <h6 className="mb-0 me-0">
                          Web<span className="pull-right">{webCount}</span>
                        </h6>
                      </Media>
                    </Media>
                    <Media>
                      <div
                        className="order-shape-success"
                        style={{ backgroundColor: "#36c478" }}
                      ></div>
                      <Media body>
                        <h6 className="mb-0 me-0">
                          All<span className="pull-right">{allCount}</span>
                        </h6>
                      </Media>
                    </Media>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Col>

          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={userLogTableHeader}
                  tableColumns={userLogTableData}
                  editId={""}
                  editMode={false}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default UserLog;
