export const CardHeaderValue = [
  { name: "Plant Name", column: "plantName" },
  { name: "Customer Code", column: "customerCode" },
  { name: "Customer Name", column: "customerName" },
  { name: "Main Agent", column: "mainAgent" },
  { name: "Invoice number", column: "invoiceNumber" },
  { name: "Invoice Amount", column: "invoiceAmount" },
  { name: "Invoice Date", column: "documentDate" },
  { name: "Baseline(LR) Date", column: "baselineDate" },
  { name: "Extended Baseline(LR) Date", column: "newBaselineDate" },
  { name: "Invoice Amount", column: "invoiceAmount" },
  { name: "Adjusted Amount", column: "adjustedAMOUNT" },
  { name: "Balance Amount", column: "balanceAmount" },
  { name: "Aging DAYS", column: "agingDAYS" },
  { name: "CD Due Date", column: "cddueDate" },
  { name: "First CD days", column: "firstCDDays" },
  { name: "CDExtensionDays", column: "cdextensionDays" },
  { name: "CD Amount", column: "cdamount" },
  { name: "Division", column: "division" },
];

/* export const outstandingBillTableHeader1 = [
  "Invoice number",
  "Document Date",
  "Inv Value",
  "Balance Amount",
  "Aging Days",
  "CD Due Date",
  "New Baseline Date",
  "CD Extension Days",
  "CD Amount",
  "Division",
];
export const outstandingBillTableData1 = [
  "invoiceNumber",
  "documentDate",
  "balanceAmount",
  "invoiceAmount",
  "agingDAYS",
  "cddueDate",
  "newBaselineDate",
  "cdextensionDays",
  "cdamount",
  "division",
]; */

export const outstandingBillTableMainHeader = [
  "Invoice number",
  "Invoice Date", //"Document Date",
  "Invoice Amount",
  "Balance Amount",
  "CD Due Date",
  "Division",
  /*  "New Baseline Date",
  "Baseline Date",
  "CD Extension Days",
  "First CD Days",
  "First CD %",
  "First CD Amount",
  "Second CD Days (Base)",
  "Second CD % (Base)",
  "Second CD Amount (Base)",
  "Third CD Days",
  "Third CD %",
  "Third CD Amount",
  "Fourth CD Days",
  "Fourth CD %",
  "Fourth CD Amount",
  "Bill Type",*/
];
export const outstandingBillTableMainData = [
  "invoiceNumber",
  "documentDate",
  "balanceAmount",
  "invoiceAmount",
  "cddueDate",
  "division",
  /*  "newBaselineDate",
  "baselineDate",
  "cdextensionDays",
  "firstCDDays",
  "cdper",
  "cdamount",
  "secondCDDays",
  "secondCDPer",
  "secondCDAmount",
  "thirdCDDays",
  "thirdCDPer",
  "thirdCDAmount",
  "fourthCDDays",
  "fourthCDPer",
  "fourthCDAmount",
  "billType",
  */
];

export const outstandingBillTableDetailedHeader = [
  "Document number",
  "Invoice number",
  "Invoice Date", //"Document Date",
  "Invoice Amount",
  "Balance Amount",
  "Aging Days",
  "CD Due Date",
  "New Baseline Date",
  "Baseline Date",
  "CD Extension Days",
  "First CD Days",
  "First CD %",
  "First CD Amount",
  "First CD Due Date",
  "Second CD Days (Base)",
  "Second CD % (Base)",
  "Second CD Amount (Base)",
  "Second CD Due Date (Base)",
  "Third CD Days",
  "Third CD %",
  "Third CD Amount",
  "Third CD Due Date",
  "Fourth CD Days",
  "Fourth CD %",
  "Fourth CD Amount",
  "Fourth CD Due Date",
  "Bill Type",
  "Dispute Invoice Status",
  "Division",
];
export const outstandingBillTableDetailedData = [
  "documentNumber",
  "invoiceNumber",
  "documentDate",
  "balanceAmount",
  "invoiceAmount",
  "agingDAYS",
  "cddueDate",
  "newBaselineDate",
  "baselineDate",
  "cdextensionDays",
  "firstCDDays",
  "cdper",
  "cdamount",
  "firstCDDueDate",
  "secondCDDays",
  "secondCDPer",
  "secondCDAmount",
  "secondCDDueDate",
  "thirdCDDays",
  "thirdCDPer",
  "thirdCDAmount",
  "thirdCDDueDate",
  "fourthCDDays",
  "fourthCDPer",
  "fourthCDAmount",
  "fourthCDDueDate",
  "billType",
  "disputeInvoiceStatus",
  "division",
];

export const CardOpenDebitValue = [
  { name: "Customer Code", column: "customerCode" },
  { name: "Customer Name", column: "customerName" },
  { name: "Main Agent", column: "mainAgent" },
  { name: "SD Billing Doc No", column: "sdBillingDocNo" },
  { name: "SD Billing Date", column: "sdBillingDate" },
  { name: "SD Bill Amount", column: "sdBillAmt" },
  { name: "Document Type", column: "documentType" },
  { name: "Debit Note Doc No", column: "debitNoteDocNo" },
  { name: "Debit Note Doc Date", column: "debitNoteDocDate" },
  { name: "Debit Note Amount", column: "debitNoteAmt" },
  { name: "Adjusted Amount", column: "adjustedAmt" },
  { name: "Balance Debit", column: "balanceDebit" },
  { name: "Ageing Days", column: "agingDays" },
  { name: "Short Text", column: "shortText" },
  { name: "Long Text", column: "longText" },
  { name: "LR Date", column: "lrDate" },
  { name: "Actual Due Date", column: "actualDueDate" },
  { name: "BR Date", column: "brDate" },

  { name: "Paid days", column: "paidDays" },
  { name: "Delay Days", column: "delayDays" },
  { name: "Expenses GL Description", column: "expensesGlDesc" },
  { name: "Division", column: "division" },
];

export const openDebitTableHeader1 = [
  "SD Billing Doc No",
  "SD Billing Date",
  "SD Bill Amount",
  "Document Type",
  "Debit Note Doc No",
  "Debit Note Doc Date",
  "Balance Debit",
  "Ageing Days",
  "Short Text",
  "LR Date",
  "Actual Due Date",
  "BR Date",
  "Paid Days",
  "Delay Days",
  "Expenses GL Description",
  "Division",
];
export const openDebitTableData1 = [
  "sdBillingDocNo",
  "sdBillingDate",
  "sdBillAmt",
  "documentType",
  "debitNoteDocNo",
  "debitNoteDocDate",
  "balanceDebit",
  "agingDays",
  "shortText",
  "lrDate",
  "actualDueDate",
  "brDate",
  "paidDays",
  "delayDays",
  "expensesGlDesc",
  "division",
];

export const CardOpenCreditValue = [
  { name: "Customer Code", column: "customerCode" },
  { name: "Customer Name", column: "customerName" },
  { name: "Main Agent", column: "mainAgent" },
  { name: "Document Type", column: "documentType" },
  { name: "Document Number", column: "documentNumber" },
  { name: "Document Date", column: "documentDate" },
  { name: "CN Amount", column: "cnAmt" },
  { name: "Adjusted Amount", column: "adjustedAmt" },
  { name: "Balance CN Amount", column: "balanceCnAmt" },
  { name: "Ageing Days", column: "agingDays" },
  { name: "Reference", column: "reference" },

  { name: "Short Text", column: "shortText" },
  { name: "Long Text", column: "longText" },
  { name: "Expenses GL Description", column: "expensesGlDesc" },
  { name: "Division", column: "division" },
];

export const openCreditTableHeader1 = [
  "Document Type",
  "Document Number",
  "Document Date",
  "Balance CN Amount",
  "Ageing Days",
  "Reference",
  "Short Text",
  "Expenses GL Description",
  "Division",
];
export const openCreditTableData1 = [
  "documentType",
  "documentNumber",
  "documentDate",
  "balanceCnAmt",
  "agingDays",
  "reference",
  "shortText",
  "expensesGlDesc",
  "division",
];

export const CardAdjustmentReportValue = [
  { name: "Customer Code", column: "customerCode" },
  { name: "Name", column: "name" },
  { name: "Invoice No", column: "invoiceNo" },
  //{ name: "Document Number", column: "invoiceDocNumber" },
  //{ name: "Document Type", column: "documentType" },

  { name: "Invoice Date", column: "postingDate" },
  { name: "LR Date", column: "baselineDate" },
  //{ name: "NDD Without Grace Date", column: "nddWithoutGraceDate" },
  { name: "CD Due Date", column: "nddWithoutGraceDate" },
  { name: "Total Invoice Amt", column: "totalInvoiceAmt" },
  { name: "Division", column: "divisionDescription" },
  //{ name: "Description", column: "divisionDescription" },

  { name: "Adjustment Date", column: "clearingDate" },
  //{ name: "CD Applicable Amount", column: "cdApplicableAmount" },
  { name: "To be received", column: "toBeReceived" },
  { name: "Receipt Document No", column: "documentNo" },
  { name: "Receipt/CN Descriptions", column: "description" },
  { name: "Receipt/CN Amount", column: "receiptCn" },
  { name: "Balance/Excess", column: "balanceExcess" },
  //{ name: "Remain Document", column: "remainDocument" },
  //{ name: "Remain Amts", column: "remainAmts" },
  { name: "Receipt/ CN Date", column: "receiptCNDate" },
  { name: "Paid Day", column: "paidDay" },
  { name: "CD Credit", column: "cdCredit" },
  { name: "CD not Eligible", column: "cdNotEligible" },
  //{ name: "Pay Term", column: "payTerm" },
  { name: "Delay days", column: "delaydays" },
];

export const adjustmentReportTableHeader1 = [
  "Invoice No",
  //"Document Number",
  //"Document Type",

  "Invoice Date", //"Posting Date",
  "LR Date", //"Baseline Date",
  "CD Due Date", // Renamed from NDD Without Grace Date",
  "Total Invoice Amt",
  "Division",
  "Adjustment Date",
  //"Description",
  //"CD Applicable Amount",
  "To be received",
  "Receipt Document No", //"Document No",
  "Receipt/CN Descriptions",
  "Receipt/CN Amount",
  "Balance/Excess",
  "CD Credit",
  "CD not Eligible",
  //"Remain Document",
  //"Remain Amts",
  "Receipt/ CN Date",
  "Paid Day",
  "Delay days",
];
export const adjustmentReportTableData1 = [
  "invoiceNo",
  //"invoiceDocNumber",
  //"documentType",

  "postingDate",
  "baselineDate",
  "nddWithoutGraceDate",
  "totalInvoiceAmt",
  "division",
  "clearingDate",
  //"divisionDescription",
  //"cdApplicableAmount",
  "toBeReceived",
  "documentNo",
  "description",
  "receiptCn",
  "balanceExcess",
  "cdCredit",
  "cdNotEligible",
  //"remainDocument",
  //"remainAmts",
  "receiptCNDate",
  "paidDay",
  "delaydays",
];

export const adjustmentReportTableHeaderAdmin1 = [
  "Invoice No",
  //"Document Number",
  //"Document Type",

  "Invoice Date", //"Posting Date",
  "LR Date", //"Baseline Date",
  "CD Due Date", // Renamed from NDD Without Grace Date",
  "Total Invoice Amt",
  "Division",
  "Adjustment Date",
  //"Description",
  //"CD Applicable Amount",
  "To be received",
  "Receipt Document No", //"Document No",
  "Receipt/CN Descriptions",
  "Receipt/CN Amount",
  "Balance/Excess",
  "CD Credit",
  "Revised Balance/Excess",
  "CD not Eligible",
  //"Remain Document",
  //"Remain Amts",
  "Receipt/ CN Date",
  "Paid Day",
  "Delay days",
  "Total Invoice Amt New",
  "Receipt Cn New",
  "Cd Received Amt New",
  "Balance Excess New",
  "Pay Term 1 With Grace Days",
  "Pay Term 1",
  "Slab1 Actual Cd Percentage",
  "Pay Term 2 With Grace Days",
  "Pay Term 2",
  "Slab2 Actual Cd Percentage",
  "Pay Term 3 With Grace Days",
  "Pay Term 3",
  "Slab3 Actual Cd Percentage",
  "Pay Term 4 With Grace Days",
  "Pay Term 4",
  "Slab4 Actual Cd Percentage",
  "Pay Term 5 With Grace Days",
  "Pay Term 5",
  "Slab5 Actual Cd Percentage",
];

export const adjustmentReportTableDataAdmin1 = [
  "invoiceNo",
  //"invoiceDocNumber",
  //"documentType",

  "postingDate",
  "baselineDate",
  "nddWithoutGraceDate",
  "totalInvoiceAmt",
  "division",
  "clearingDate",
  //"divisionDescription",
  //"cdApplicableAmount",
  "toBeReceived",
  "documentNo",
  "description",
  "receiptCn",
  "balanceExcess",
  "cdCredit",
  "revisedBalanceExcess",
  "cdNotEligible",
  //"remainDocument",
  //"remainAmts",
  "receiptCNDate",
  "paidDay",
  "delaydays",
  "totalInvoiceAmtNew",
  "receiptCnNew",
  "cdReceivedAmtNew",
  "balanceExcessNew",
  "payTerm1WithGraceDays",
  "payTerm1",
  "slab1ActualCdPercentage",
  "payTerm2WithGraceDays",
  "payTerm2",
  "slab2ActualCdPercentage",
  "payTerm3WithGraceDays",
  "payTerm3",
  "slab3ActualCdPercentage",
  "payTerm4WithGraceDays",
  "payTerm4",
  "slab4ActualCdPercentage",
  "payTerm5WithGraceDays",
  "payTerm5",
  "slab5ActualCdPercentage",
];
export const adjustmentReportTableHeaderAdminMainView = [
  "Invoice No",
  "Invoice Date", //"Posting Date",
  "LR Date", //"Baseline Date",
  "CD Due Date", // Renamed from NDD Without Grace Date",
  "Division",

  "Total Invoice Amt",
  "Receipt CN",
  "CD Received Amt",
  "Balance (Excess)",

  "Actual CD %",
  "Paid Day",
  "Pay Term",
  "CD not Eligible",
  "Delay days",
  "CD Remarks",
];

export const adjustmentReportTableDataAdminMainView = [
  "invoiceNo",
  "postingDate",
  "baselineDate",
  "nddWithoutGraceDate",
  "division",
  "totalInvoiceAmtNew",
  "receiptCnNew",
  "cdReceivedAmtNew",
  "balanceExcessNew",
  "actualCDPercentage",
  "paidDay",
  "payTerm",
  "cdNotEligible",
  "delaydays",
  "cdReceivedRevisedAmount",
];

export const adjustmentReportTableHeaderAdminDetailedView = [
  "Invoice No",
  "Document Number",
  "Document Type",
  "Invoice Date", //"Posting Date",
  "LR Date", //"Baseline Date",
  "CD Due Date", // Renamed from NDD Without Grace Date",
  "Before Tax Value",
  "Division",
  "Clearing Doc Number",
  "Clearing Date",
  "Receipt Document No", //"Document No",
  "Description",

  "Total Invoice Amt",
  "Receipt CN",
  "Cd Received Amt",
  "Balance (Excess)",
  "Receipt/ CN Date",

  "Actual CD %",
  "Paid Day",
  "Pay Term",
  "CD not Eligible",
  "Delay days",
  "CD Credit",

  "Pay Term 1",
  "1st Slab Actual CD %",
  "Pay Term 2",
  "2nd Slab Actual CD %",
  "Pay Term 3",
  "3rd Slab Actual CD %",
  "Pay Term 4",
  "4th Slab Actual CD %",
  //"Pay Term 5",
  // "5th Slab Actual CD %",
  "CD Remarks",
];

export const adjustmentReportTableDataAdminDetailedView = [
  "invoiceNo",
  "invoiceDocNumber",
  "documentType",
  "postingDate",
  "baselineDate",
  "nddWithoutGraceDate",
  "beforeTaxValue",
  "division",
  "clearDocNo",
  "clearingDate",
  "documentNo",
  "description",
  "totalInvoiceAmtNew",
  "receiptCnNew",
  "cdReceivedAmtNew",
  "balanceExcessNew",
  "receiptCNDate",
  "actualCDPercentage",
  "paidDay",
  "payTerm",
  "cdNotEligible",
  "delaydays",
  "cdCredit",
  "payTerm1",
  "slab1ActualCdPercentage",
  "payTerm2",
  "slab2ActualCdPercentage",
  "payTerm3",
  "slab3ActualCdPercentage",
  "payTerm4",
  "slab4ActualCdPercentage",
  "cdReceivedRevisedAmount",
  //  "payTerm5",
  //  "slab5ActualCdPercentage",
];

export const ledgerTableHeader = [
  "Voucher Date",
  "Type",
  "Voucher Number",
  "Particulars",
  "Debit Amount",
  "Credit Amount",
];
export const ledgerTableData = [
  "voucherDate",
  "type",
  "voucherNumber",
  "particulars",
  "debitAmount",
  "creditAmount",
];
