import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { Button, MenuItem } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import {
  PersonSearch,
  PictureAsPdf,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  exportToExcelLedger,
  exportToPDFLedger,
  getAllLedger,
} from "../../api/service";

import CommonLoader from "../../common/commonLoader";
import Breadcrumb from "../../common/breadcrumb";
import { Col } from "react-bootstrap";
import CommonCardDesign from "../../common/commonCardDesign";
import {
  CardOpenDebitValue,
  ledgerTableData,
  ledgerTableHeader,
} from "../../common/commonCardConstant";
import moment from "moment";
import { useCustomerCode } from "../../context/CustomerCodeProvider";
import { formatMoney, getDivisionName } from "../../../constants/utils";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const Ledger = () => {
  const classes = useStyles();
  const history = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [consolidateData, setConsolidateData] = useState([]);

  const filterInitialValues = {
    searchFinancialYear: "FY 2024-25",
    searchMonth: "All", //moment(new Date()).format("YYYY-MM-DD"),
  };

  const { selectedCustomerCode, selectedCustomerName } = useCustomerCode();

  const [searchData, setSearchData] = useState(filterInitialValues);

  useEffect(() => {
    if (selectedCustomerCode) {
      filterData();
    }
  }, [selectedCustomerCode]);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  const loadListData = () => {
    setLoading(true);
    getAllLedger(selectedCustomerCode ?? "", page, rowsPerPage, "").then(
      (res) => {
        setLoading(false);
        setData(res.response.responseObject);
        setConsolidateData(res.response.responseMap.consolidateList);
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const filterData = () => {
    getAllLedger(selectedCustomerCode, page, rowsPerPage, "").then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setConsolidateData(res.response.responseMap.consolidateList);
    });
  };

  const exportData = (isPdf) => {
    setLoading(true);
    if (isPdf) {
      exportToPDFLedger(
        selectedCustomerCode,
        selectedCustomerName,
        `${selectedCustomerName.replace(" ", "_")}_${moment(new Date()).format(
          "DD_MM_YYYY_HH_mm_SS"
        )}`
      ).then(() => {
        setLoading(false);
      });
    } else {
      exportToExcelLedger(
        selectedCustomerCode,
        selectedCustomerName,
        `${selectedCustomerName.replace(" ", "_")}_${moment(new Date()).format(
          "DD_MM_YYYY_HH_mm_SS"
        )}`
      ).then(() => {
        setLoading(false);
      });
    }
  };

  const getMonthMenuItems = () => {
    if (searchData.searchFinancialYear === "FY 2024-25") {
      //const previousMonth = currentMonth - 1;
      return [<MenuItem value="All">All</MenuItem>];

      /* <MenuItem value="04">April</MenuItem>,
        <MenuItem value="05">May</MenuItem>,
        <MenuItem value="06">June</MenuItem>,
        <MenuItem value="07">July</MenuItem>,
        <MenuItem value="08">August</MenuItem>,
        <MenuItem value="09">September</MenuItem>,
        <MenuItem value="10">October</MenuItem>,
        <MenuItem value="11">November</MenuItem>, */
    }
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="Ledger" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Col lg={6} xs={12}>
            <Card className="height-equal">
              <CardBody className={classes.cardbody}>
                <div className="user-status table-responsive text-center">
                  <table className="table table-bordernone mb-0">
                    {/* <thead>
                      <tr>
                        <th scope="col">Division</th>
                        <th scope="col">SD Bill Amount</th>

                        <th scope="col">Customer Code</th>
                        <th scope="col">Balance Debit</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {consolidateData.map((data) => (
                        <tr>
                          <td>{data.particulars}</td>
                          <td className="digits">
                            {formatMoney(data.debitAmount?.toFixed(0))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} xs={12}>
            <Card>
              <CardBody className={classes.cardbody}>
                <Grid container spacing={1.5} className={classes.muiGrid}>
                  {/*  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Date"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="fullWidth"
                      name="searchDate"
                      value={searchData.searchDate}
                      onChange={onFilterInputChange}
                    />
                  </Grid> */}

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Financial Year"
                      size="small"
                      select
                      id="fullWidth"
                      name="searchFinancialYear"
                      value={searchData.searchFinancialYear}
                      onChange={onFilterInputChange}
                    >
                      <MenuItem value="FY 2024-25">FY 2024-25</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Month"
                      size="small"
                      select
                      id="fullWidth"
                      name="searchMonth"
                      value={searchData.searchMonth}
                      onChange={(e) => onFilterInputChange(e)}
                    >
                      {getMonthMenuItems()}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => filterData()}
                    >
                      <PersonSearch></PersonSearch>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        setSearchData(filterInitialValues);
                        loadListData();
                      }}
                    >
                      <Refresh></Refresh>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        exportData(false);
                      }}
                    >
                      <SimCardDownloadRounded></SimCardDownloadRounded>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        exportData(true);
                      }}
                    >
                      <PictureAsPdf></PictureAsPdf>
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Col>

          <CardBody className={classes.cardbody}>
            <div>
              <CommonCardDesign
                tableData={data}
                tableHeaders={ledgerTableHeader}
                tableColumns={ledgerTableData}
                tableModal={CardOpenDebitValue}
                rowsPerPageOptions={[25, 50, 100]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ float: "right" }}
                displayPagination={false}
                from={"Ledger"}
                hideActionButton
              />
            </div>
          </CardBody>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default Ledger;
