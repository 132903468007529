import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommonDialog from "../../common/commonDialog";
import { Link } from "react-router-dom";
import moment from "moment";
import { saveBalanceConfirmationMobile } from "../../api/service";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
}));

const BalanceConfirmationModal = (props) => {
  const classes = useStyles();
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [openAddMoreClaimDialog, setOpenAddMoreClaimDialog] = useState(false);
  const [showResubmitPopup, setShowResubmitPopup] = useState(false);

  const [openPinDialog, setOpenPinDialog] = useState(false);

  const [verification, setVerification] = useState("PIN");
  const {
    open,
    onCancel,
    handleClose,
    onSave,
    data,
    setData,
    showSubmitButton,
    selectedItemBCStatus,
  } = props;
  const initialValues = {
    period: "",
    claim: "",
    detailsOfClaim: "",
    amount: "",
  };

  const [claimDetails, setClaimDetails] = useState([]);

  useEffect(() => {
    setClaimDetails(data.claimDetails);
  }, [data.claimDetails]);
  const [claimData, setClaimData] = useState(initialValues);
  const onInputChange = (event) => {
    const { name, value } = event.target;
    data[name] = value;
    if (name === "balanceConfirmationStatus") {
      if (value === "Disagreed") {
        if (data.claimDetails != null && data.claimDetails.length > 0) {
          setClaimDetails(data.claimDetails);
        } else {
          claimDetails.push(initialValues);
          setClaimDetails([...claimDetails]);
        }
      } else {
        setClaimDetails([]);
      }
    }
    setData({ ...data });
  };

  const pinPopUp = () => {
    setVerification("PIN");
  };

  const otpPopUp = () => {
    setVerification("OTP");
  };

  const handleVerificationDialogClose = () => {
    setOpenVerificationDialog(false);
  };

  const handlePinDialogClose = () => {
    setOpenPinDialog(false);
  };

  const handleSubmit = () => {
    data.agreedDate = moment(new Date()).format("DD-MM-YYYY");
    data.customerBalanceType = data.balanceConfirmationType;
    data.claimDetails = claimDetails;
    console.log(data);

    saveBalanceConfirmationMobile(data).then((res) => {
      setOpenPinDialog(false);
      handleClose();
    });
    /*  if (data.balanceConfirmationStatus === "Agree") {
      setOpenVerificationDialog(true);
      // handleClose();
    } else {
      setOpenVerificationDialog(true);
    } */
  };

  const handleSubmitAgree = () => {
    setOpenVerificationDialog(false);
    setOpenPinDialog(false);

    if (verification === "PIN") {
      setOpenPinDialog(true);
      // handleVerificationDialogClose();
    } else {
      setOpenPinDialog(true);
    }
  };

  const onClaimPopUpInputChange = (event) => {
    const { name, value } = event.target;
    claimData[name] = value;
    setClaimData({ ...claimData });
  };

  const onFirstDataChange = (event) => {
    const { name, value } = event.target;
    const claimDataNew = claimDetails[0];
    claimDataNew[name] = value;
    claimDetails[0] = claimDataNew;
    setClaimDetails([...claimDetails]);
  };

  const addClaims = () => {
    claimDetails.push(claimData);
    setClaimDetails([...claimDetails]);
    setClaimData(initialValues);
    setOpenAddMoreClaimDialog(false);
  };

  const resetBalanceConfirmationData = () => {
    setClaimDetails([]);
    data.agreedDate = null;
    data.balanceConfirmationStatus = "Pending";
    data.customerBalanceAmount = null;
    data.revisionNo = data.revisionNo + 1;
    data.claimDetails = [];
    setData({ ...data });
    setShowResubmitPopup(false);
  };

  return (
    <>
      <CommonDialog
        onCancel={handleClose}
        onSave={() => {
          if (selectedItemBCStatus === "Pending") {
            if (data.balanceConfirmationStatus !== "Pending") {
              setOpenVerificationDialog(true);
            }
          } else if (selectedItemBCStatus === "Disagreed") {
            if (data.oldRevisionNo === data.revisionNo) {
              setShowResubmitPopup(true);
            } else {
              setOpenVerificationDialog(true);
            }
          } else {
            alert(
              "Approval Process completed for selected Balance confirmation"
            );
          }
        }}
        open={open}
        primaryButtonLabel={
          data.balanceConfirmationStatus !== "Disagreed"
            ? "Submit"
            : "Re-Submit Confirmation"
        }
        title={"Balance Confirmation Details"}
        maxWidth="md"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div>
              The balance on your account as per our records bear an amount of
              <b>
                {" "}
                <Link to={data.filePath} target="_blank">
                  Rs.{data.closingBalance?.toFixed(0)}-
                  {data.balanceConfirmationType}{" "}
                </Link>
              </b>
              (DR - receivable from you / CR - payable to you). A detailed
              statement on the same is attached to this letter. We are
              requesting you to confirm that apart from the above, there is no
              due payable from Modenik Lifestyle Private Limited as on date for
              the balance on <b>{data.balanceConfirmationDate}</b>. In case,
              there is any difference then you are requested to share the
              details of the same as under:
            </div>
            <hr />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <TextField
              fullWidth
              id="balanceConfirmationStatus"
              select
              label="Status"
              defaultValue=""
              size="small"
              name="balanceConfirmationStatus"
              value={data.balanceConfirmationStatus}
              onChange={onInputChange}
            >
              <MenuItem key={"Pending"} value={"Pending"}>
                {"Pending"}
              </MenuItem>
              <MenuItem key={"Agreed"} value={"Agreed"}>
                {"Agree"}
              </MenuItem>
              <MenuItem key={"Disagreed"} value={"Disagreed"}>
                {"Disagree"}
              </MenuItem>
            </TextField> */}
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="balanceConfirmationStatus"
                value={data.balanceConfirmationStatus}
                onChange={onInputChange}
              >
                <FormControlLabel
                  value="Agreed"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#f91e26",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 23,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      fontSize={15}
                      sx={{ color: "#292929" }}
                    >
                      Agree
                    </Typography>
                  }
                  className={classes.checkFont}
                />
                {data.revisionNo === 1 && (
                  <FormControlLabel
                    value="Disagreed"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#f91e26",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 23,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        fontSize={15}
                        sx={{ color: "#292929" }}
                      >
                        Disagree
                      </Typography>
                    }
                    className={classes.checkFont}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          {data.balanceConfirmationStatus === "Disagreed" && (
            <>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-search"
                  label="What is your balance?"
                  size="small"
                  type="number"
                  name="customerBalanceAmount"
                  value={data.customerBalanceAmount}
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <hr />
                <div>
                  *Add you claims details for your breakup amount{" "}
                  <>
                    {data.customerBalanceAmount && (
                      <span style={{ color: "#f91e26" }}>
                        Rs.{" "}
                        {data.balanceConfirmationType === "CR"
                          ? data.closingBalance - data.customerBalanceAmount > 0
                            ? (
                                data.closingBalance - data.customerBalanceAmount
                              ).toFixed(0)
                            : -(
                                data.closingBalance - data.customerBalanceAmount
                              ).toFixed(0)
                          : (
                              data.closingBalance - data.customerBalanceAmount
                            ).toFixed(0)}
                      </span>
                    )}
                  </>
                </div>
              </Grid>
              {claimDetails?.length > 0 && (
                <>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      id="period"
                      label="Period (Month/Year)"
                      size="small"
                      type="text"
                      name="period"
                      value={claimDetails[0].period}
                      onChange={onFirstDataChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      id="claim"
                      label="Claim"
                      size="small"
                      type="text"
                      name="claim"
                      value={claimDetails[0].claim}
                      onChange={onFirstDataChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      id="detailsOfClaim"
                      label="Details of Claim"
                      size="small"
                      type="text"
                      name="detailsOfClaim"
                      value={claimDetails[0].detailsOfClaim}
                      onChange={onFirstDataChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      id="amount"
                      label="Amount"
                      size="small"
                      type="number"
                      name="amount"
                      value={claimDetails[0].amount}
                      onChange={onFirstDataChange}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Typography variant="inherit">
                      To add more claims click on add claims button{" "}
                      <Button
                        color={"secondary"}
                        variant="outlined"
                        type="button"
                        className={`${classes.btnClass}`}
                        onClick={() => setOpenAddMoreClaimDialog(true)}
                      >
                        Add Claim
                      </Button>
                    </Typography>
                  </Grid>

                  {claimDetails.length > 1 && (
                    <Grid item sm={12} xs={12}>
                      <div className="user-status table-responsive text-center">
                        <table className="table table-bordernone mb-0">
                          <thead>
                            <tr>
                              <th scope="col">Period (Month/Year)</th>
                              <th scope="col">Claim</th>
                              <th scope="col">Details of Claim</th>
                              <th scope="col">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {claimDetails.map((row, index) => {
                              if (index > 0) {
                                return (
                                  <tr key={index}>
                                    <td>{row.period}</td>
                                    <td>{row.claim}</td>
                                    <td>{row.detailsOfClaim}</td>
                                    <td>{row.amount}</td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
          {/* <Grid item style={{ margin: "auto" }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="confirmation"
                name="confirmation"
                value={confirmation}
                onChange={(e) => setConfirmation(e.target.value)}
                style={{ display: "block" }}
              >
                <FormControlLabel
                  value="Agree"
                  control={<Radio />}
                  label="Agree"
                  className={classes.checkLabelFont}
                  onClick={handleAgree}
                />
                <FormControlLabel
                  value="Disagree"
                  control={<Radio />}
                  label="Disagree"
                  className={classes.checkLabelFont}
                  onClick={handleDisagree}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
        </Grid>
      </CommonDialog>

      <CommonDialog
        open={openVerificationDialog}
        title="Digital Verification"
        onCancel={() => setOpenVerificationDialog(false)}
        primaryButtonLabel={"Next"}
        onSave={handleSubmitAgree}
      >
        <Typography variant="body1">
          Choose option for digital verification
        </Typography>

        <Grid item style={{ margin: "auto" }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="verification"
              name="verification"
              value={verification}
              onChange={(e) => setVerification(e.target.value)}
              style={{ display: "block" }}
            >
              <FormControlLabel
                value="PIN"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label="PIN"
                className={classes.checkLabelFont}
                onClick={pinPopUp}
              />
              <FormControlLabel
                value="OTP"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label="OTP"
                className={classes.checkLabelFont}
                onClick={otpPopUp}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </CommonDialog>

      <CommonDialog
        open={openPinDialog}
        title="Digital Verification"
        onCancel={() => setOpenPinDialog(false)}
        primaryButtonLabel={"Submit"}
        onSave={handleSubmit}
      >
        <Typography variant="body1">
          By entering PIN/OTP you are confirming our balance mentioned
        </Typography>

        <Grid container spacing={2} style={{ margin: "5px 0", width: "80%" }}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="outlined-search"
              label="PIN"
              name="PIN"
              type="text"
              size="small"
            />
          </Grid>
        </Grid>
      </CommonDialog>

      <CommonDialog
        open={openAddMoreClaimDialog}
        title="Add More Claim"
        onCancel={() => setOpenAddMoreClaimDialog(false)}
        primaryButtonLabel={"Add"}
        onSave={addClaims}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="period"
              label="Period (Month/Year)"
              size="small"
              type="text"
              name="period"
              value={claimData.period}
              onChange={onClaimPopUpInputChange}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="claim"
              label="Claim"
              size="small"
              type="text"
              name="claim"
              value={claimData.claim}
              onChange={onClaimPopUpInputChange}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="detailsOfClaim"
              label="Details of Claim"
              size="small"
              type="text"
              name="detailsOfClaim"
              value={claimData.detailsOfClaim}
              onChange={onClaimPopUpInputChange}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="amount"
              label="Amount"
              size="small"
              type="number"
              name="amount"
              value={claimData.amount}
              onChange={onClaimPopUpInputChange}
            />
          </Grid>
        </Grid>
      </CommonDialog>
      <CommonDialog
        open={showResubmitPopup}
        title="Resubmit Balance Confirmation"
        onCancel={() => {
          setShowResubmitPopup(false);
        }}
        primaryButtonLabel={"Yes"}
        secondaryButtonLabel={"No"}
        onSave={() => {
          resetBalanceConfirmationData();
        }}
      >
        <Typography variant="body1">
          Do you want to re-submit balance confirmation details?
        </Typography>
      </CommonDialog>
    </>
  );
};

export default BalanceConfirmationModal;
